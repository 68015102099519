import React from 'react'

function Insurances() {
    return (
        <div className='insurance-wrapper'>
            <div className='column'>
                <h2>Egyéb biztosításaink</h2>
                <p>Balesetbiztosításon kívül további biztosítási csomagokra is tudunk személyre szabott ajánlatot adni. Az életbiztosítástól kezdve az egészségbiztosításon át egészen a lakás-, gépjármű-, és nyugdíjbiztosításig bezárólag.</p>
                <div className='additional-info'>
                        <h4>Balesetbiztosításunk 4 x 4 csomagja közül választhatod ki a Neked és családodnak leginkább megfelelőt, így a bajban sem maradtok anyagi segítség nélkül.</h4>
                </div>
            </div>
            <div className='more-wrapper'>
                <div className='insurance-card'>
                    <h3>Életbiztosítás</h3>
                    <a className='default-btn' href="https://www.aslan.hu/eletbiztositas-generali-biztosito-gyor/?utm_source=website&utm_medium=button&utm_campaign=eletbiztositas&utm_id=ebiztosito" target="_blank" rel="noreferrer">Érdekel!</a>
                </div>
                <div className='insurance-card'>
                    <h3>Egészségbiztosítás</h3>
                    <a className='default-btn' href="https://www.aslan.hu/egeszsegbiztositas-generali-biztosito-gyor/?utm_source=website&utm_medium=button&utm_campaign=egeszsegbiztositas&utm_id=ebiztosito" target="_blank" rel="noreferrer" >Érdekel!</a>
                </div>
                <div className='insurance-card'>
                    <h3>Lakásbiztosítás</h3>
                    <a className='default-btn' href="https://www.aslan.hu/lakasbiztositas-generali-biztosito-gyor/?utm_source=website&utm_medium=button&utm_campaign=lakasbiztositas&utm_id=ebiztosito" target="_blank" rel="noreferrer">Érdekel!</a>
                </div>
                <div className='insurance-card'>
                    <h3>Nyugdíjbiztosítás</h3>
                    <a className='default-btn' href="https://www.aslan.hu/nyugdijbiztositas-generali-biztosito-gyor/?utm_source=website&utm_medium=button&utm_campaign=nyugdijbiztositas&utm_id=ebiztosito" target="_blank" rel="noreferrer">Érdekel!</a>
                </div>
            </div>
        </div>
    )
}

export default Insurances
