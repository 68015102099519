import React, {useEffect, useContext, useRef} from 'react';
import { GlobalContext } from '../Contexts/GlobalContext';
import emailjs from '@emailjs/browser';

function OrderPopup() {
    
    const OrderPopupRef = useRef(null);
    const FormRef = useRef();
    const {user, setUser, orderPopup, setOrderPopup} = useContext(GlobalContext);

    const URL = "https://script.google.com/macros/s/AKfycbyyAw984sii2xzIcVmO0MrlGyZPVh83_DNbwMQeyiVS_4XztQUq2-RpDhph93z9by0/exec";

    const submitOrder = (event) =>{
        event.preventDefault()
        fetch(URL, {method: 'POST', body: new FormData(OrderPopupRef.current)})
        .then(res => {res.json();console.log("Sikerült.")})
        .then(() => {setOrderPopup(false);setUser({}); window.location.reload();})
        .catch(err => console.log(err))
        emailjs.sendForm('gmail', 'template_5tqdcyh', OrderPopupRef.current, 'VOn2JV3s2a9KjBzwW')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }


    return (
        <>
        <div className='popup-overlay'>
            <div className='popup-wrapper'>
                <div className='popup-close'>
                <button className='close-btn' onClick={(event) => {event.preventDefault(); setOrderPopup(false)}}><i class="gg-close"></i></button>
                </div>
                <div className='popup'>
                    <form method="post" ref={OrderPopupRef} name="google-sheet" onSubmit={submitOrder}>
                        <h2>Tekintse át az adatait!</h2>
                        <label htmlFor="fullname">Teljes név:</label>
                        <input required type="text" name="name" value={user.fullName} 
                        onChange={({target}) => setUser({...user, fullName: target.value})}/>
                        <label htmlFor="email">E-mail cím: </label>
                        <input required type="text" name="email" 
                        value={user.emailAddress} onChange={({target}) => setUser({...user, emailAddress: target.value})}/>
                        <label htmlFor="phone">Telefonszám: </label>
                        <input required type="text" name="phone" 
                        value={user.phoneNumber} onChange={({target}) => setUser({...user, phoneNumber: target.value})}/>
                        <label htmlFor="birthdate">Születési dátum:</label>
                        <input required type="date" name="birthdate"  max="2022-01-01" value={user.birthDate} 
                        onChange={({target}) => setUser({...user, birthDate: target.value})}/>
                        <label htmlFor="zipcode">Irányítószám: </label>
                        <input required type="text" name="zipcode"   
                        value={user.zipCode} onChange={({target}) => setUser({...user, zipCode: target.value})}/>
                        <label htmlFor="">Választott csomag:</label>
                        <input required type="text" name="package" defaultValue={user.userPackage} />
                        <div className='popup-info'>
                            <input type="text" className='popup-btn' name="work" value={user.workStatus}/>
                            <input type="text" className='popup-btn' name="kids" value={user.familyStatus}/>
                        </div>
                        <button className='default-btn'>Beküldés</button>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default OrderPopup