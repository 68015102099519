import React from 'react'

function Navigation() {
    return (

        <div className='navigation'>
            <div className='navigation-wrapper'>
                <div className='column'><h2>eBiztosító</h2></div>
                <a className='default-btn' href="#calc">Kalkuláció</a>
            </div> 
        </div>
    )
}

export default Navigation
