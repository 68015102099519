import React from 'react';


function Footer() {
  return (
    <>
      <div className='footer'>
        <div className='footer-wrapper'>
          <div className="column">
            <h2>eBiztosító</h2>
            <p>Az Aslan Partner Bt. a Generali Biztosító hivatalos
            győri partnere. Teljeskörű biztosítási ügyintézés,
            több mint 10 éves tapasztalattal.
            Keressen minket bizalommal!</p>
          </div>
          <div className="column">
            <h2>Biztosítások</h2>
            <div><a href="https://www.aslan.hu/balesetbiztositas-generali-biztosito-gyor/">Balesetbiztosítás</a></div>
			      <div><a href="https://www.aslan.hu/nyugdijbiztositas-generali-biztosito-gyor/">Nyugdíjbiztosítás</a></div>
			      <div><a href="https://www.aslan.hu/egeszsegbiztositas-generali-biztosito-gyor/">Egészségbiztosítás</a></div>
			      <div><a href="https://www.aslan.hu/eletbiztositas-generali-biztosito-gyor/">Életbiztosítás</a></div>
			      <div><a href="https://www.aslan.hu/lakasbiztositas-generali-biztosito-gyor/">Lakásbiztosítás</a></div>
			      <div><a href="https://www.aslan.hu/balesetbiztositas-generali-biztosito-gyor/">Utasbiztosítás</a></div>
          </div>
          <div className="column">
            <h2>Információk</h2>
            <div><a href="../documents/ebiztositodata.pdf" target='_blank'>Adatkezelési tájékoztató</a></div>
          </div>
          <div className="column">
            <h2>Elérhetőségek</h2>
            <div><a href="mailto:info@ebiztosito.com">info@ebiztosito.com</a></div>
          </div>
        </div>
      </div>
      <div className='footer-info'>
        <p>Minden jog fenntartva. | eBiztosító.com by <a href="https://www.aslan.hu" target="_blank">Aslan Partner</a> 2022.</p>
      </div>
    
    </>
  )
}

export default Footer