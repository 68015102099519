import React, {useContext} from 'react'
import {GlobalContext} from '../Contexts/GlobalContext'

function MoreOffer() {

    const {optimalOffer, setOptimalOffer, showOptimalTable, setShowOptimalTable, moreOffer, setMoreOffer, moreOfferPackageType, setMoreOfferPackageType, showMoreOfferTable, setShowMoreOfferTable, packageNumber, setPackageNumber, tableRef } = useContext(GlobalContext)

    function scrollToTable() {
      if(showMoreOfferTable === false){
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        return null
      }
    }

    return(
        moreOffer.map(({packageID, packageName, packageType, packagePrice, packageButton}) => {
          return(
            <div key={packageID} className='card'>
              <h2 className='packageName'>{packageName}</h2>
              <h2 className='packagePrice'>{Math.round(packagePrice/365)} Ft/nap</h2>
              <h2 className='packageType'>{packageType}</h2>
              <button className='default-btn' onClick={() => {setPackageNumber(packageID); setMoreOfferPackageType(packageType); setShowMoreOfferTable(!showMoreOfferTable); scrollToTable()}}>{showMoreOfferTable && packageNumber === packageID ? "Bezárás" : "Részletek"}</button>
              <p>(Görgess lefele!)</p>
            </div>
          )
        })
      )
}

export default MoreOffer