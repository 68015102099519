import React, { useState, useEffect, useRef } from 'react';
import OptimalOffer from '../OptimalOffer/OptimalOffer';
import OptimalTable from '../OptimalTable/OptimalTable';
import kidsPackageData from '../Database/kidsPackage.json';
import kidsData from '../Database/kids.json';
import ygenPackageData from '../Database/ygenPackage.json';
import ygenData from '../Database/ygen.json';
import backgroundData from '../Database/background.json';
import backgroundPackageData from '../Database/backgroundPackage.json';
import supportData from '../Database/support.json';
import supportPackageData from '../Database/supportPackage.json';
import {GlobalContext} from '../Contexts/GlobalContext'
import MoreOffer from '../MoreOffer/MoreOffer';
import MoreOfferTable from '../MoreOfferTable/MoreOfferTable';
import OrderPopup from '../OrderPopup/OrderPopup';


function Main() {

    const [user, setUser] = useState({ fullName: "", emailAddress: "", phoneNumber: "", birthDate: "", zipCode: "", userPackage : "", workStatus: "", familyStatus:""})

    const [optimalOffer, setOptimalOffer] = useState([])
    const [optimalTable, setOptimalTable] = useState([])
    const [showOptimalTable, setShowOptimalTable] = useState(false)
    const [tableType, setTableType] = useState("")

    const [moreOffer, setMoreOffer] = useState([])
    const [showMoreOffer, setShowMoreOffer] = useState(false)
    const [packageNumber, setPackageNumber] = useState(0)

    const [moreOfferPackageType, setMoreOfferPackageType] = useState("")
    const [moreOfferTable, setMoreOfferTable] = useState([])
    const [showMoreOfferTable, setShowMoreOfferTable] = useState(false)

    const [orderPopup, setOrderPopup] = useState(false)

    const [scrollContent, setScrollContent] = useState(false)
    const scrollRef = useRef();

    const tableRef = useRef();


    function calculateAge(){
        let today = new Date();
        let inputDate = new Date(document.getElementById("birthdate").value);
        let birthDetails = {year:inputDate.getFullYear()}
        let currentYear = today.getFullYear();
        let birthYear;
        birthYear = currentYear - birthDetails.year;
        
        if(birthYear > 1 && birthYear <= 18){
            setOptimalOffer(kidsPackageData.filter(offer => offer.packageType === "Optimál"))
            setOptimalTable(kidsData)
            setMoreOffer(kidsPackageData.slice(1,4))
            setMoreOfferTable(kidsData)
            setTableType("kids")
        }
        if(birthYear > 18 && birthYear <=35){
            setOptimalOffer(ygenPackageData.filter(offer => offer.packageType === "Optimál"))
            setOptimalTable(ygenData)
            setMoreOffer(ygenPackageData.slice(1,4))
            setMoreOfferTable(ygenData)
            setTableType("ygen")
        }
        if(birthYear > 35 && birthYear <=55){
            setOptimalOffer(backgroundPackageData.filter(offer => offer.packageType === "Optimál"))
            setOptimalTable(backgroundData)
            setMoreOffer(backgroundPackageData.slice(1,4))
            setMoreOfferTable(backgroundData)
            setTableType("background")
        }
        if(birthYear > 55){
            setOptimalOffer(supportPackageData.filter(offer => offer.packageType === "Optimál"))
            setOptimalTable(supportData)
            setMoreOffer(supportPackageData.slice(1,4))
            setMoreOfferTable(supportData)
            setTableType("support")
        }
        
    }

    function scrollToDiv() {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <>
            <div className='two-column-wrapper' id='calc'>
                <div className='column'>
                    <h2>Ingyenes díjkalkuláció</h2>
                    <div className='additional-info'>
                        <h4>Balesetbiztosításunk 4 x 4 csomagja közül választhatod ki a Neked és családodnak leginkább megfelelőt, így a bajban sem maradtok anyagi segítség nélkül.</h4>
                    </div>
                    <img src={require('../images/ebiztositoember.jpg')}/>
                    <p>Balesetek bárhol és bármikor érhetnek: otthonodban, a kertben, főzés közben, vagy amikor épp úton vagy. Elég egy rossz lépés vagy egy pillanatnyi figyelmetlenség és bekövetkezhet a baj.</p>

                </div>
                <div className='column'>
                    <form onSubmit={e => {e.preventDefault(); calculateAge(); scrollToDiv()}}>

                        <label htmlFor="fullname">Teljes név:</label>
                        <input required type="text" placeholder="Minta János" 
                        value={user.fullName} onChange={({target}) => setUser({...user, fullName: target.value})}/>

                        <label htmlFor="email">E-mail cím: </label>
                        <input required type="email" placeholder="minta.janos@gmail.com" 
                        value={user.emailAddress} onChange={({target}) => setUser({...user, emailAddress: target.value})}/>

                        <label htmlFor="phone">Telefonszám: </label>
                        <input required type="tel" placeholder="36701234567" pattern="[0-9]+" maxLength="11" 
                        value={user.phoneNumber} onChange={({target}) => setUser({...user, phoneNumber: target.value})}/>

                        <label htmlFor="birthdate">Születési dátum:</label>
                        <input required type="date" id="birthdate" max="2022-01-01" 
                        onChange={({target}) => setUser({...user, birthDate: target.value})} />

                        <label htmlFor="zipcode">Irányítószám: </label>
                        <input required type="tel" placeholder="1234"  pattern="[0-9]+" maxLength="4" size="4"
                        value={user.zipCode} onChange={({target}) => setUser({...user, zipCode: target.value})}/>
                        <div className='radio-wrapper'>
                            <div><p>Jelenleg aktív foglalkoztatott?</p></div>
                            <div>
                                <ul className='radio-btn'>
                                    <li>
                                        <input type="radio" id="yes_work" name="work" value="Foglalkoztatott"
                                        onChange={({target}) => setUser({...user, workStatus: target.value})}/>
                                        <label htmlFor="yes_work">Igen</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="no_work" name="work" value="Nem foglalkoztatott"
                                        onChange={({target}) => setUser({...user, workStatus: target.value})}/>
                                        <label htmlFor="no_work">Nem</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='radio-wrapper'>
                            <div><p>Van gyermeke?</p></div>
                            <div>
                                <ul className='radio-btn'>
                                    <li>
                                        <input required type="radio" id="yes_child" name="child" value="Van gyermek"
                                        onChange={({target}) => setUser({...user, familyStatus: target.value})}/>
                                        <label htmlFor="yes_child">Igen</label>
                                    </li>
                                    <li>
                                        <input required type="radio" id="no_child" name="child" value="Nincs gyermek"
                                        onChange={({target}) => setUser({...user, familyStatus: target.value})}/>
                                        <label htmlFor="no_child">Nem</label>
                                    </li>
                                </ul>        
                            </div>
                        </div>
                        <div>
                            <input required type="checkbox" name="checkbox"/>
                            <label htmlFor="checkbox"> Megismertem és elfogadom az <a href="../documents/ebiztositodata.pdf" target="_blank">Adatkezelési tájékoztatóban</a> foglaltakat.</label>
                        </div>

                        <button className='default-form-btn'>Kalkulálok!</button>
                        
                    </form>
                </div>
            </div>    
            <div className='wrapper'>
                <GlobalContext.Provider value={{user, setUser,optimalOffer, setOptimalOffer, optimalTable, setOptimalTable, showOptimalTable, setShowOptimalTable, moreOffer, setMoreOffer, showMoreOffer, setShowMoreOffer, moreOfferPackageType, setMoreOfferPackageType, moreOfferTable, setMoreOfferTable, showMoreOfferTable, setShowMoreOfferTable, orderPopup, setOrderPopup, tableType, setTableType, packageNumber, setPackageNumber, scrollContent, setScrollContent, tableRef}}>
                    <div ref={scrollRef} className="optimal-wrapper">{<OptimalOffer />}</div>
                    {showOptimalTable ? <div className="table-wrapper"><OptimalTable /></div> : null}
                    <div className='more-wrapper'>{showMoreOffer ? <MoreOffer /> : null}</div>
                    <div ref={tableRef} className='table-wrapper'>{showMoreOfferTable ? <MoreOfferTable /> : null}</div>
                    {orderPopup? <OrderPopup /> : null}
                </GlobalContext.Provider>
            </div>
        </>
        
    )
    
}

export default Main;