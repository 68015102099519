import React from 'react';
import Navigation from './Navigation/Navigation';
import Header from './Header/Header';
import Main from './Main/Main';
import Insurances from './Insurances/Insurances';
import Footer from './Footer/Footer';
import './App.css'

function App() {
  return (
    <div>
        <Navigation />
        <Header />
        <Main />
        <Insurances />
        <Footer />
    </div>
  );
}

export default App;
