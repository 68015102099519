import React, {useContext} from 'react';
import { GlobalContext } from '../Contexts/GlobalContext';


function OptimalTable() {

    const { user, setUser, optimalTable, setOptimalTable, showOptimalTable, setShowOptimalTable, 
        showMoreOffer, setShowMoreOffer, orderPopup, setOrderPopup, tableType, setTableType } = useContext(GlobalContext)


    const optimalTableHead = () =>{
        if (tableType === "kids"){
            return(
                <tr key={1} >
                    <th className='table-text'>Kölyök Csomag (Optimál)</th>
                    <th className='table-price'>Biztosítási keretösszeg</th>
                </tr>
            )
        }
        if (tableType === "ygen"){
            return(
                <tr key={2} >
                    <th className='table-text'>Y-generáció Csomag<br></br>(Optimál)</th>
                    <th className='table-price'>Biztosítási keretösszeg</th>
                </tr>
            )
        }
        if (tableType === "background"){
            return(
                <tr key={3} >
                    <th className='table-text'>Háttér Csomag (Optimál)</th>
                    <th className='table-price'>Biztosítási keretösszeg</th>
                </tr>
            )
        }
        if (tableType === "support"){
            return(
                <tr key={4} >
                    <th className='table-text'>Háttér Csomag (Optimál)</th>
                    <th className='table-price'>Biztosítási keretösszeg</th>
                </tr>
            )
        }
    }

    const optimalTableBody = () =>{
        return optimalTable.map(({serviceID, serviceName, optimalPrice}) => {
            return(
                <tr key={serviceID} >
                    <td className='table-text'>{serviceName}</td>
                    <td className='table-price'>{optimalPrice}</td>
                </tr>
            )
        })
    }


    return(
        <>
            <div className='table-grid'>
                <table>
                    <thead>
                        {optimalTableHead()}
                    </thead>
                    <tbody>
                        {optimalTableBody()}
                    </tbody>
                </table>
            </div>
            <div className='table-buttons'>
                <button className='default-btn' onClick={() => setUser({...user,  userPackage: "Optimál"}, setOrderPopup(true))}>Érdekel!</button>
                <button className='default-btn' onClick={() => setShowMoreOffer(!showMoreOffer)}>{showMoreOffer ? "Bezárás" : "További csomagok"}</button>
            </div>
        </>
    )

}

export default OptimalTable