import React, {useState, useContext} from 'react'
import {GlobalContext} from '../Contexts/GlobalContext'


function OptimalOffer() {

  const {optimalOffer, setOptimalOffer, showOptimalTable, setShowOptimalTable} = useContext(GlobalContext)


  return(
    optimalOffer.map(({packageID, packageName, packageType, packagePrice, packageButton}) => {
      return(
        <div key={packageID} className='card'>
          <h2 className='packageName'>{packageName}</h2>
          <h2 className='packagePrice'>{Math.round(packagePrice/365)} Ft/nap</h2>
          <h2 className='packageType'>{packageType}</h2>
          <button className='default-btn' onClick={() => setShowOptimalTable(!showOptimalTable)}>{showOptimalTable ? "Bezárás" : "Részletek"}</button>
          <p>(Görgess lefele!)</p>
        </div>
      )
    })
  )
}

export default OptimalOffer