import React, {useContext} from 'react';
import { GlobalContext } from '../Contexts/GlobalContext';


function MoreOfferTable() {

    const {  user, setUser, optimalOffer, setOptimalOffer, moreOffer, setMoreOffer, showMoreOffer, setShowMoreOffer, moreOfferPackageType, setMoreOfferPackageType, moreOfferTable, setMoreOfferTable, orderPopup, setOrderPopup, tableType, setTableType, packageNumber, setPackageNumber, tableRef} = useContext(GlobalContext)

    const moreOfferTableHead = () =>{

        if(moreOfferPackageType === "Prémium"){
            return optimalOffer.map(({packageID, packageName, packageInfo}) => {
                return(
                    <tr key={packageID}>
                      <th className='table-text'>{packageName} (Prémium)</th>
                      <th className='table-price'>{packageInfo}</th>
                    </tr>
                )
            })
            
        }
        if(moreOfferPackageType === "Bázis"){
            return optimalOffer.map(({packageID, packageName, packageInfo}) => {
                return(
                    <tr key={packageID}>
                      <th className='table-text'>{packageName} (Bázis)</th>
                      <th className='table-price'>{packageInfo}</th>
                    </tr>
                )
            })
            
        }
        if(moreOfferPackageType === "Mini"){
            return optimalOffer.map(({packageID, packageName, packageInfo}) => {
                return(
                    <tr key={packageID}>
                      <th className='table-text'>{packageName} (Mini)</th>
                      <th className='table-price'>{packageInfo}</th>
                    </tr>
                )
            })
            
        }
    }



    const moreOfferTableBody = () => {

        if(moreOfferPackageType === "Prémium"){
            return moreOfferTable.map(({serviceID, serviceName, premiumPrice}) => {
                return(
                    <tr key={serviceID}>
                        <td className='table-text'>{serviceName}</td>
                        <td className='table-price'>{premiumPrice}</td>
                    </tr>
                )
            })      
        }
        if(moreOfferPackageType === "Bázis"){
            return moreOfferTable.map(({serviceID, serviceName, basicPrice}) => {
                return(
                    <tr key={serviceID}>
                        <td className='table-text'>{serviceName}</td>
                        <td className='table-price'>{basicPrice}</td>
                    </tr>
                )
            })      
        }
        if(moreOfferPackageType === "Mini"){
            return moreOfferTable.map(({serviceID, serviceName, minimumPrice}) => {
                return(
                    <tr key={serviceID}>
                        <td className='table-text'>{serviceName}</td>
                        <td className='table-price'>{minimumPrice}</td>
                    </tr>
                )
            })      
        }
    }

    return(
        <>  
            <div className='table-grid'>
                <table>
                    <thead>
                        {moreOfferTableHead()}
                    </thead>
                    <tbody>
                        {moreOfferTableBody()}
                    </tbody>
                </table>
            </div>
            <div className='table-button'>
                <button className='default-btn' onClick={() => {setUser({...user,  userPackage: moreOfferPackageType}); setOrderPopup(true)}}>Érdekel!</button>
            </div>
        </>
    )
}

export default MoreOfferTable