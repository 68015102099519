import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'

function Header() {
    return (
        <header>
            <div className='header-wrapper'>
                <div className='column'>
                    <h2>Készülj fel a váratlan helyzetekre!</h2>
                    <p className="header-text">Ha balesetbiztosításra van szükséged válaszd az eBiztosítót. Néhány adatod megadásával villámgyors kalkulációt készítünk és megtaláljuk a számodra legmegfelelőbb megoldást. Válaszd ki a Neked és családodnak leginkább megfelelőt, így a bajban sem maradtok anyagi segítség nélkül.</p>
                    <a className='default-btn' href="#calc">Ingyenes kalkuláció</a>
                </div>
                <div className='column'>
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        className='landing-swiper-container'
                        id='landing'
                        tag='section'
                        loop={true}
                        autoplay={{disableOnInteraction: false}}
                    >
                    <SwiperSlide key={1} className='swiper-image'>
                        <img src={require("../images/ebiztosito01.png")} alt="" />
                    </SwiperSlide>
                    <SwiperSlide key={2} className='swiper-image'>
                        <img src={require("../images/ebiztosito02.png")} alt="" />
                    </SwiperSlide>
                    <SwiperSlide key={3} className='swiper-image' >
                        <img src={require("../images/ebiztosito03.png")} alt=""/>
                    </SwiperSlide>
                    <SwiperSlide key={4} className='swiper-image' >
                        <img src={require("../images/ebiztosito04.png")} alt=""/>
                    </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </header>
    )
};

export default Header;